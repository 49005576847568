#InstructionNext {
    border-radius: 7px;
    width: 100px;
    height: 45px;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

#InstructionNext:hover {
    background-color: gainsboro;
}