.ButtonGrid {
    display: grid;
    justify-content: center;
    margin-top: 10px;
}

#DK {
    grid-column: 1;
    margin-right: 10px;
    background-color: rgb(255, 87, 87);
    height: 40px;
    border-radius: 10px;
    border: 0px;
    -webkit-user-select: none;
    user-select: none;
}

#G {
    grid-column: 2;
    background-color: rgb(106, 255, 87);
    height: 40px;
    border-radius: 10px;
    border: 0px;
    -webkit-user-select: none;
    user-select: none;
}

#SG {
    grid-row: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    margin-top: 10px;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: bisque;
    -webkit-user-select: none;
    user-select: none;
}

#SG:hover {
    background-color: rgb(210, 183, 150);
    cursor: pointer;
}

#DK:hover {
    background-color: rgb(211, 72, 72);
    cursor: pointer;
}

#G:hover {
    background-color: rgb(92, 220, 75);
    cursor: pointer;
}